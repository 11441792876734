import React from 'react';

import './Sidebar.css';
import GitHubIcon from 'simple-icons/icons/github';
import StackOverflowIcon from 'simple-icons/icons/stackoverflow';
import LinkedinIcon from 'simple-icons/icons/linkedin';
import InstagramIcon from 'simple-icons/icons/instagram';
import SoundCloudIcon from 'simple-icons/icons/soundcloud';
import GmailIcon from 'simple-icons/icons/gmail';

const SidebarItems = [
    {'icon': GitHubIcon, 'link': 'https://github.com/kbruccoleri'},
    {'icon': StackOverflowIcon, 'link': 'https://stackoverflow.com/users/9962307/kevin-bruccoleri'},
    {'icon': LinkedinIcon, 'link': 'https://www.linkedin.com/in/kevin-bruccoleri-b263a287/'},
    {'icon': InstagramIcon, 'link': 'https://www.instagram.com/kevinbruccoleri/'},
    {'icon': SoundCloudIcon, 'link': 'https://soundcloud.com/kevin-bruccoleri/tracks'},
    {'icon': GmailIcon, 'link': 'mailto:kevinbruccoleri@gmail.com'},
];

const Sidebar = () =>
    <div className="sidebar">
        <ul>
         {SidebarItems.map(({icon, link}) => {
             const svg = icon.svg.replace('<svg', `<svg style="fill: #${icon.hex}"`);
             return (
                 <li key={'sidebar-'+ icon.title}>
                    <a href={link} className="sidebar-icon" title={icon.title}
                        dangerouslySetInnerHTML={{ __html: svg }} />
                 </li>
             )
         })}
        </ul>
    </div>;

export default Sidebar;