import React from 'react';

import Section from "../../generic/Section";
import './About.css';

const About = () => {
    return (
        <Section
            name="about"
            content={
                <div className="section--inner-content">
                    <h5>Hi, my name is</h5>
                    <h1>Kevin Bruccoleri.</h1>
                    <p>
                        I'm a Software Engineer based in the New York City area.
                        I work at <a className="underline-hover-link" href="https://greenhouse.io"
                           rel="noopener noreferrer" target="_blank"
                        >Greenhouse Software</a>.
                    </p>
                </div>
            }
        />
    );
};

export default About;