import React from 'react';

import ProjectList from '../../generic/project/ProjectList';
import Javascript from "../../generic/technology/Javascript";
import MySQL from "../../generic/technology/MySQL";
import Python from "../../generic/technology/Python";
import ReactJS from "../../generic/technology/ReactJS";
import Section from "../../generic/Section";

const sideProjectList = [
    {
        title: 'Habitual: Calendar Tracker',
        subtitle: 'A ReactNative JS app that aims to help build habits',
        link: 'habitual',
        technologies: [<Javascript/>, <ReactJS/>]
    },
    {
        title: 'Hangman React',
        subtitle: 'A new take on an old game.',
        link: 'https://github.com/kbruccoleri/hangman-react',
        technologies: [<Javascript/>, <ReactJS/>]
    },
    {
        title: 'Hibernate-Rebinder',
        subtitle: 'A tool for Play 1.x framework to recreate SQL queries from log files',
        link: 'https://github.com/kbruccoleri/hibernate-rebinder',
        technologies: [<Python/>]
    },
    {
        title: 'WhiteboardJS',
        link: 'https://github.com/kbruccoleri/WhiteboardJS',
        subtitle: 'A basic whiteboard written using vanilla Javascript',
        technologies: [<Javascript/>, <MySQL/>]
    },
    {
        title: 'Nylas Participants Display',
        subtitle: 'A plugin for the Nylas Mail email application',
        link: 'https://github.com/kbruccoleri/nylas-participants-display',
        technologies: [<Javascript/>]
    }
];

const SideProjects = () => {
    return (
        <Section
            name="side-projects"
            content={
                <React.Fragment>
                    <h1>Side Projects</h1>
                    <ProjectList projects={sideProjectList} />
                </React.Fragment>
            }
        />
    );
};

export default SideProjects;