import React from 'react';
import PropTypes from 'prop-types';

import TechnologyAvatar from './TechnologyAvatar';
import './Technology.css';


const Technology = (props) => {
    const {name, avatarSettings, chipSettings} = props;
    const chipStyles = {
        backgroundColor: chipSettings.backgroundColor,
        color: chipSettings.color ? chipSettings.color : '#000'
    };

    return (
        <div role="button" className="technology--container" style={chipStyles}>
            <TechnologyAvatar backgroundColor={avatarSettings.backgroundColor}>
                {avatarSettings.path}
            </TechnologyAvatar>
            <span className="technology--name">{name}</span>
        </div>
    );
};

Technology.propTypes = {
    name: PropTypes.string.isRequired,
    avatarSettings: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
        path: PropTypes.element.isRequired
    }).isRequired,
    chipSettings: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
    }).isRequired
};

export default Technology;