import React from 'react';
import PropTypes from 'prop-types';

import IFrame from '../../generic/IFrame'

class Track extends React.Component {
    render() {
        return (
            <div className="track-container">
                <IFrame title={"Track-" + this.props.trackId} width="100%" height="166" scrolling="no" frameBorder="no"
                        allow="autoplay"
                        onLoad={() => this.props.onLoad(this.props.trackId)}
                        src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
                        this.props.trackId +
                        "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true" +
                        "&show_user=true&show_reposts=false&show_teaser=true"}
                />
            </div>
        );
    }
}

Track.propTypes = {
    trackId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired
};

export default Track;