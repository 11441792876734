import React from 'react';
import PropTypes from 'prop-types';

const IFrame = (props) => <iframe title={props.title} src={props.src} {...props}/>;

IFrame.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    onLoad: PropTypes.func
};


export default IFrame;