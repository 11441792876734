import React from "react";
import {Link} from 'react-scroll';

import './Navbar.css'

const Navbar = ({onClick, sections}) => (
    <nav>
        {sections.map(section => (
            <Link to={section.id}
                  className="underline-hover-link"
                  smooth={"linear"} duration={500}
                  offset={-50} containerId="app--main-content-container"
                  key={"section-link-" + section.id}
            >
                {section.name}
            </Link>
        ))}
        <a id="resume-button" href="https://s3.amazonaws.com/kevinbruccoleri.com/KevinBruccoleri.pdf"
           rel="noopener noreferrer" target="_blank">
            Resume
        </a>
    </nav>
);

export default Navbar;