import React from 'react';

import './Music.css'
import TrackList from './TrackList';
import Section from "../../generic/Section";

const TrackIds = [
    85263956,
    85372543,
    175843966
];

const Music = () => {
    return (
        <Section
            name="music"
            content={
                <React.Fragment>
                    <h1>Music</h1>
                    <TrackList trackIds={TrackIds}/>
                </React.Fragment>
            }
        />
    );
};

export default Music;