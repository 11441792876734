import React from 'react';
import PropTypes from 'prop-types';

import './TechnologyAvatar.css';

const TechnologyAvatar = ({backgroundColor, children}) => (
    <div className="technology-avatar--container" style={{backgroundColor: backgroundColor}}>
        <svg className="technology-avatar" focusable="false" viewBox="0 0 128 128" aria-hidden="true" role="presentation">
            {children}
        </svg>
    </div>
);

TechnologyAvatar.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
};

export default TechnologyAvatar;