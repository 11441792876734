import React from 'react';

import LoadingGif from './Loading.gif';
import './Loading.css'

const Loading = ({show}) => {
    if (!show)
        return <React.Fragment/>;

    return (
        <div className="loading-wrapper">
            <img alt="Loading" src={LoadingGif} />
        </div>
    );
};

export default Loading;