import React from 'react';
import PropTypes from 'prop-types';

import './Project.css'

class Project extends React.Component {
    render() {
        const { project } = this.props;
        const timeFrame = project.timeFrame ?
            (
                <span className="project-card--header-timeframe">({project.timeFrame})</span>
            ) :
            '';

        return (
            <div className="project-card--container">
                <div className="project-card--header-container">
                    <div className="project-card--header-content">
                        <span className="project-card--header-title">
                            <a href={project.link}>{project.title}</a>
                            {timeFrame}
                        </span>
                        <span className="project-card--sub-header-content">
                            {project.subtitle}
                        </span>
                    </div>
                </div>
                <div className="project-card--technology-list-wrapper">
                    <div>
                        {project.technologies.map((tech, i) => (
                            <React.Fragment key={i}>{tech}</React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

Project.propTypes = {
    project: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        link: PropTypes.string.isRequired,
        timeFrame: PropTypes.string,
        technologies: PropTypes.array
    }).isRequired
};

export default Project;