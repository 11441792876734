import React from 'react';

import Java from "../../generic/technology/Java";
import Javascript from "../../generic/technology/Javascript";
import MySQL from "../../generic/technology/MySQL";
import PHP from "../../generic/technology/PHP";
import PostgreSQL from "../../generic/technology/PostgreSQL";
import ReactJS from "../../generic/technology/ReactJS";
import Ruby from "../../generic/technology/Ruby";
import RubyOnRails from "../../generic/technology/RubyOnRails";
import Section from "../../generic/Section";
import ProjectList from "../../generic/project/ProjectList";

const workExperienceList = [
    {
        title: 'Greenhouse',
        position: 'Software Engineer, Product',
        subtitle: 'Talent Acquisition Suite designed to optimize your entire recruiting process',
        link: 'https://greenhouse.io',
        technologies: [<Ruby/>, <RubyOnRails/>, <Javascript/>, <ReactJS/>, <PostgreSQL/>],
        timeFrame: 'January 2019 - Present',
    },
    {
        title: 'SmartAsset',
        position: 'Full Stack Engineer',
        subtitle: 'A FinTech startup that creates interactive calculators and tools to make personal finance more accessible',
        link: 'https://github.com/kbruccoleri/hibernate-rebinder',
        technologies: [<Java/>, <Javascript/>, <MySQL/>],
        timeFrame: 'September 2016 - January 2019',
    },
    {
        title: 'Friendsy',
        position: 'CTO',
        link: 'https://friendsyapp.com',
        subtitle: 'A college-based social network startup to help connect students',
        technologies: [<PHP/>, <Javascript/>, <MySQL/>],
        timeFrame: 'February 2014 - March 2016',
    },
];

const WorkExperiences = () => {
    return (
        <Section
            name="work-experiences"
            content={
                <React.Fragment>
                    <h1>Work Experiences</h1>
                    <ProjectList projects={workExperienceList} />
                </React.Fragment>
            }
        />
    );
};

export default WorkExperiences;