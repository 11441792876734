import React from 'react';
import {Element} from 'react-scroll';

const Section = ({name, content}) => {
    return (
        <section id={name}>
            <div className="section-content">
                <Element name={name}/>
                {content}
            </div>
        </section>
    );
};

export default Section;