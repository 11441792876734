import React from 'react';
import PropTypes from 'prop-types';
import Project from './Project';

const ProjectList = ( {projects} ) => (
    <React.Fragment>
        {projects.map( (project, i) => <Project key={"project-" + i} project={project} /> )}
    </React.Fragment>
);

ProjectList.propTypes = {
    projects: PropTypes.array.isRequired
};

export default ProjectList;