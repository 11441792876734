import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../../generic/loading/Loading';
import Track from './Track';

class TrackList extends React.Component {

    constructor(props) {
        super(props);

        let loadStatus = {};
        this.props.trackIds.forEach(trackId => loadStatus[trackId] = false);
        this.state = {
            loadStatusMap: loadStatus
        };

        this.trackLoaded = function(trackId) {
            console.log(`Track ${trackId} Registered as Loaded`);
            const { loadStatusMap } = this.state;
            loadStatusMap[trackId] = true;
            this.setState({ loadStatusMap });
        }.bind(this);
    }

    areAllLoaded = () =>
        Object.values(this.state.loadStatusMap).reduce(
            (accumulator, currentValue) => accumulator && currentValue
        );

    render() {
        const tracks = this.props.trackIds.map(trackId =>
            <Track key={trackId} trackId={trackId} onLoad={this.trackLoaded}/>
        );
        return (
            <React.Fragment>
                <Loading show={!this.areAllLoaded()}/>
                <div className={classNames('track-list-container', {'show': this.areAllLoaded()})}>
                    {tracks}
                </div>
            </React.Fragment>
        );
    }
}

TrackList.propTypes = {
    trackIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default TrackList;