import React from 'react';
import {Element, Events} from 'react-scroll';

import './App.css';
import About from "./components/sections/about/About";
import SideProjects from "./components/sections/projects/SideProjects";
import Music from "./components/sections/music/Music";
import WorkExperiences from "./components/sections/work/WorkExperiences";
import Footer from "./components/layout/footer/Footer";
import Navbar from "./components/layout/navbar/Navbar";
import Sidebar from "./components/layout/sidebar/Sidebar";

const sections = [
    { id: 'about', name: 'About', component: <About/> },
    { id: 'work-experiences', name: 'Work Experiences', component: <WorkExperiences/> },
    { id: 'side-projects', name: 'Side Projects', component: <SideProjects/> },
    { id: 'music', name: 'Music', component: <Music/> },
];

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollSnapEnabled: true
        };
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function() {
            this.setState({scrollSnapEnabled: false});
        }.bind(this));

        Events.scrollEvent.register('end', function() {
            this.setState({scrollSnapEnabled: true});
        }.bind(this));
    }
    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div className="app">
              <Navbar sections={sections}/>
              <Sidebar/>
              <Element id="app--main-content-container"
                       className={this.state.scrollSnapEnabled ? 'scroll-snap-enabled' : ''}
              >
                  {sections.map(section => <React.Fragment key={"section-" + section.id}>{section.component}</React.Fragment>)}
                  <Footer/>
              </Element>
            </div>
        )
    }
}

export default App;
